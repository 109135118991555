<template>
  <container>
    <div class="white--text text-center">
      <h2 class="mb-5">Coming Soon:</h2>

      <h1>Sony's New Rebate Center</h1>
      <p class="mb-5"></p>
      <p class="mb-0">Please check back soon</p>
      <p>Thank you</p>
    </div>
  </container>
</template>

<script>
export default {
  name: "Register",
  metaInfo: {
    title: "Register"
  }
};
</script>
